export default {
    async getAll(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/damages/client/${clientUuid}`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: damages.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async getDetails(uuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/damages/${uuid}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: damages.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async create(data) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/damages`, {
                status:        data.status,
                amount:        parseInt(data.amount.replace(',', ''), 10),
                description:   data.description,
                address_uuid:  data.addressUuid,
                employee_uuid: data.employeeUuid,
            });
        } catch (error) {
            console.log('🚀 ~ file: damages.api.js ~ create ~ error', error);
            throw error;
        }
    },

    async update(damageUuid, damageData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/damages/${damageUuid}`, {
                status:        damageData.status,
                amount:        parseInt(damageData.amount.replace(',', ''), 10),
                description:   damageData.description,
                employee_uuid: damageData.employeeUuid,
            });
        } catch (error) {
            console.log('🚀 ~ file: damages.api.js ~ update ~ error', error);
            throw error;
        }
    },

    destroyURL(uuid) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/damages/${uuid}`;
    },
};
