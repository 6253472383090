<template>
    <div class="flex flex-1 flex-col card card-f-h">
        <ConfirmationModal codeConfirmation @success="actionSuccess" />

        <ModalAddDamage
            :modalAddDamage="modalAddDamage"
            :clientUuid="clientUuid"
            @refresh="actionSuccess"
            @close="modalAddDamage = false"
        />

        <ModalEditDamage
            :modalVisible="modalEditDamage"
            :damageUuid="selectedDamageUuid"
            @refresh="getAll"
            @close="modalEditDamage = false"
        />

        <header class="flex items-center justify-between h-10 border-b border-gray-200 pb-4 mb-5">
            {{ $t('client_details.damages') }}
            <el-button type="primary" @click="showModalAddDamage">
                {{ $t('common.add') }}
            </el-button>
        </header>

        <DamagesTable
            :tableData="damagesData"
            @handleDelete="handleDelete"
            @handleEdit="handleEdit"
        />

        <NoDataInformation :data="noDataInformation" waitKey="loading.damages" />

        <InitialLoader v-if="$wait.is('loading.initial_damages')" />
    </div>
</template>
<script>
import Api from './damages.api';

export default {
    components: {
        ModalAddDamage:  () => import(/* webpackChunkName: "Client/ModalAddDamage" */ './DamagesModalAdd'),
        ModalEditDamage: () => import(/* webpackChunkName: "Client/ModalEditDamage" */ './DamagesModalEdit'),
        DamagesTable:    () => import(/* webpackChunkName: "Client/DamagesTable" */ './DamagesTable'),
    },

    data() {
        return {
            noDataInformation:     false,
            clientUuid:            this.$route.params.uuid,
            selectedDamageUuid:    null,
            damagesData:           [],
            modalAddDamage:        false,
            modalEditDamage:       false,
            modalAddDamageVisible: false,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_damages');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.damages');

            try {
                this.damagesData = await Api.getAll(this.clientUuid);
                this.noDataInformation = this.damagesData.length;
            } finally {
                this.$wait.end('loading.damages');
                this.$wait.end('loading.initial_damages');
            }
        },

        showModalAddDamage() {
            this.modalAddDamage = true;
        },

        handleEdit(uuid) {
            this.selectedDamageUuid = uuid;
            this.modalEditDamage = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(uuid),
                actionType: 'destroy',
            });
        },

        actionSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
